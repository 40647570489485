<template>
  <div>
    <div class="container">
      <div class="row career-container">
        <div class="col-12">
          <h2 class="mt-0 align-center | custom-heading">Open Positions</h2>
        </div>

        <!-- Search Bar -->

        <div class="accordion">
          <div class="search-container">
            <img src="../assets/search.svg" class="search-icon" />
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search"
              class="search-bar"
            />
          </div>
          <div
            v-if="filteredSections.length < 1"
            :key="index"
            class="mt-4 text-center p-2"
          >
            No Jobs available
          </div>
          <div v-else>
            <div
              v-for="(item, index) in filteredSections"
              :key="index"
              class="accordion-item"
            >
              <div class="accordion-header" @click="toggleAccordionItem(index)">
                <div style="display: flex; flex-direction: column">
                  <div v-if="item.title === 'Universities'" class="uni-text">
                    For Graduating Students: Select Your University to Apply
                  </div>
                  <div>
                    {{
                      //adding this check because we need this whole title, url fetches BSSO in JSON response from manatal
                      item.title === "BSSO"
                        ? "Business, Strategy, Design, Sales, Operations and Quality(BSDSOQ)"
                        : item.title
                    }}
                    <span>({{ item.content.length }})</span>
                  </div>
                </div>
                <img
                  :style="{
                    transform: item.expanded
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                  }"
                  src="../assets/down.svg"
                />
              </div>
              <div v-if="item.expanded" class="content-container">
                <a
                  :href="`${config.jobs_url}/${job.hash}`"
                  target="_blank"
                  v-for="(job, index) in item.content"
                  :key="index"
                  class="accordion-content"
                >
                  <span>{{ index + 1 }}. </span>
                  <span>{{ job.position_name }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../utils/config";
import { getRequest } from "../services/axios-client";

export default {
  name: "InterviewPreparation",
  data() {
    return {
      config: config,
      jobsSection: [],
      filteredSections: [],
      searchQuery: "",
    };
  },
  async mounted() {
    const apiUrl = config.jobs_api_url;
    try {
      const response = await getRequest(apiUrl);
      const jobs = response.data.results;
      const categorized = {};

      jobs.forEach((job) => {
        const category = job.organization_name.split("-")[0].trim();
        if (!categorized[category]) {
          categorized[category] = [];
        }
        categorized[category].push(job);
      });

      // Custom sorting function based on order
      const customSort = (a, b) => {
        const order = [
          "Engineering",
          "BSSO",
          "Office Operations",
          "Universities",
        ];

        const indexA = order.indexOf(a.title);
        const indexB = order.indexOf(b.title);

        // Place categories not found in the order array at the end
        return (
          (indexA === -1 ? Infinity : indexA) -
          (indexB === -1 ? Infinity : indexB)
        );
      };

      const sortedCategories = Object.keys(categorized)
        .map((key) => ({
          title: key,
          content: categorized[key],
          expanded: false,
        }))
        .sort(customSort);
      if (categorized["Engineering"]) {
        categorized["Engineering"].sort((a, b) => {
          if (
            a.position_name.includes("Mobile Application Developer") &&
            !b.position_name.includes("Mobile Application Developer")
          ) {
            return -1; // Move a before b
          }
          if (
            !a.position_name.includes("Mobile Application Developer") &&
            b.position_name.includes("Mobile Application Developer")
          ) {
            return 1;
          }
          return 0;
        });
      }
      this.jobsSection = sortedCategories;
      this.filteredSections = this.jobsSection;
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  },

  watch: {
    searchQuery(newValue, oldValue) {
      // Check if the search query has changed before filtering
      if (newValue !== oldValue) {
        this.filteredSections = this.jobsSection
          .map((section) => ({
            ...section,
            expanded: section.content.some((job) =>
              job.position_name.toLowerCase().includes(newValue.toLowerCase()),
            ),
            content: section.content.filter((job) =>
              job.position_name.toLowerCase().includes(newValue.toLowerCase()),
            ),
          }))
          .filter((section) => section.content.length > 0);
      }
    },
  },
  methods: {
    toggleAccordionItem(index) {
      this.filteredSections[index] = {
        ...this.filteredSections[index],
        expanded: !this.filteredSections[index].expanded,
      };
    },
  },
};
</script>

<style scoped>
/* Your scoped styles go here */
</style>

<style scoped>
.uni-text {
  font-weight: 400;
  color: #ed7323;
  padding-bottom: 5px;
  font-size: 20px;
}
.arrow-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}
</style>

<style scoped>
* {
  transition: 250ms ease-in-out;
}
a {
  color: #ffffff;
  text-decoration: none;
}
a:hover {
  color: #ffffff;
}
.search-container {
  position: relative;
}
.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  height: 35px;
  opacity: 0.6;
}
.search-bar {
  padding: 16px 50px;
  border-radius: 8px;
  outline: none;
  color: #fff;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.05);
  max-width: 100%;
}

.accordion {
  max-width: 880px; /* Adjust the width as needed */
  background-color: rgba(32, 32, 32, 1);
  padding: 16px !important;
  border-radius: 16px;
  margin-top: 16px;
}

.accordion-item {
  margin-bottom: 8px;
  background-color: rgba(32, 32, 32, 1);
  font-weight: 500;
  font-size: 24px;

  border: none;
}

.accordion-item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.accordion-header {
  padding-block: 16px;
  margin-inline: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.content-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 16px;
}
/* .content-container > a{
border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}  */
.accordion-content {
  margin-block: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  font-size: 18px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.accordion-content:hover {
  background: #f07b22;
  cursor: pointer;
}
</style>

<style scoped>
.container {
  overflow: hidden;
}

.row {
  justify-content: center;
}

.card-box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 25px 0px 0px 0px;
}

.align-center {
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.144962px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-hover:hover {
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .career-container {
    padding: 0 !important;
  }

  .card-box2 > h4 {
    font-weight: 500 !important;
    font-size: 18px !important;
  }
}
/* Responsive font size for h2 heading */
@media (min-width: 769px) {
  .career-container div > h2 {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 55px;
    font-weight: bold;
    color: #ffffff;
  }
}
.career-link {
  text-decoration: none;
  color: inherit;
}
</style>
