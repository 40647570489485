<template>
  <div class="culture-banner">
    <div class="content">
      <h2>Read About Our <span class="highlight">Culture</span></h2>
      <p>
        At Cowlar Design Studio, our culture defines who we are and what we
        expect from every team member.
      </p>
      <p class="important">
        You <span class="must-read">Must Read</span> This Before Applying!
        <br />
        <span class="expect-text"
          >Expect questions about this in your initial interview.</span
        >
      </p>
      <button class="see-document" @click="redirectToDocument">
        See Document
      </button>
    </div>
    <img src="/assets/astronaut.svg" alt="Astronaut" class="astronaut" />
  </div>
</template>
<script>
export default {
  name: "CultureBanner",
  methods: {
    redirectToDocument() {
      window.location.href =
        "https://docs.google.com/presentation/d/1tKibguPrj8yQxlz8kyJgV6BCzxOTH-bERbRDEuiF22M/edit?usp=sharing";
    },
  },
};
</script>

<style scoped>
.culture-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2b2b2b;
  color: white;
  padding: 40px 50px;
  border-radius: 20px; /* Increased border radius for the card */
  margin: 20px auto;
  max-width: 900px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #2b2b2b;
}

.content {
  max-width: 60%;
}

h2 {
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.highlight {
  color: #f47726;
}

.important {
  font-weight: bold;
  margin-top: 10px;
}

.expect-text {
  font-style: italic;
  font-size: 0.875rem;
  color: #aeaeae;
}

.must-read {
  color: #f47726;
}

.see-document {
  background-color: #f28636;
  border: none;
  color: white;
  font-weight: bold; /* Bold text for the button */
  padding: 10px 20px;
  border-radius: 10px; /* More rounded corners for the button */
  cursor: pointer;
  margin-top: 10px;
}

.see-document:hover {
  background-color: #d86b2a; /* Slightly darker color on hover */
}

.astronaut {
  max-width: 35%;
  height: auto;
}

@media (max-width: 768px) {
  .culture-banner {
    flex-direction: column;
    text-align: center;
  }

  .content {
    max-width: 100%;
  }

  .astronaut {
    max-width: 50%;
    margin-top: 20px;
  }
}
</style>
