<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <h2 class="mt-0 mb-0 align-center d-flex flex-row">
          <div class="hiring-title">
            OUR <span class="text-orange"> HIRING PROCESS </span>
          </div>
        </h2>
        <p class="mx-auto mt-4 text-center | custom-width">
          All interviews except Final are conducted Online. Exceptions can be
          based on special cases.
        </p>
      </div>
      <div class="col-12">
        <div class="row pb-5">
          <div
            :key="process.key"
            v-for="process in hiringProcess"
            class="col-lg-3 col-md-6 col-sm-12 mt-3"
          >
            <div class="row">
              <div class="col-8 text-center mx-auto">
                <img
                  class="mt-3 mb-3"
                  style="width: 35px"
                  :src="getImgUrl(process.id, 'assets/numbers')"
                  alt="num"
                />
                <div>
                  <span class="hiring-heading">
                    <strong>{{ process.title }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12" style="text-align: center">
                <img
                  class="my-3 hiring-img"
                  style="width: 180px , height: 180px, aspect-ratio:1"
                  :src="getImgUrl(process.id, 'assets/hiring_process')"
                  alt=""
                />
              </div>
              <div class="col-8 text-center mx-auto">
                <span class="description">
                  {{ process.description }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      hiringProcess: [],
    };
  },
  methods: {
    getImgUrl(pic, path) {
      return require("../" + path + "/" + pic + ".png");
    },
  },
  mounted() {
    this.hiringProcess = [
      {
        id: 1,
        title: "Interview Prep.",
        description:
          "In order to nail your interview, it is important that you thoroughly prepare the relevant Interview Prep Doc.                            Lets respect each other's time we will only talk to people who have actually prepared.",
      },
      {
        id: 2,
        title: "Apply",
        description:
          "Submit your application by applying to the relevant job position, please carefully fill out the job application form.",
      },
      {
        id: 3,
        title: "ShortListing",
        description:
          "We sort through applications to find candidates whose skills and experience match the job requirements.",
      },
      {
        id: 4,
        title: "Screening Interview/Test",
        description:
          "Once you have prepared from the Interview Prep Doc, we will be testing that whether you REALLY prepared or not. Depending upon the position you might be invited to the office to take the onsite test as well. For special cases, this test could be done online.",
      },
      {
        id: 5,
        title: "Task",
        description:
          "Your technical and real-world problem-solving capabilities are assessed through practical tasks.",
      },
      {
        id: 6,
        title: "Technical Interview-Lead",
        description:
          "A technical interview with a team lead to assess your expertise in the relevant field.",
      },
      {
        id: 7,
        title: "Technical Interview-CTO",
        description:
          "In-depth technical interview with our CTO to assess your technical proficiency and problem-solving capabilities for team compatibility.",
      },
      {
        id: 8,
        title: "Final Interview-CEO",
        description:
          "You will meet with CEO for a final decision-making interview.",
      },
    ];
  },
};
</script>

<style scoped>
.custom-width {
  width: fit-content;
}
.text-orange {
  color: #ed7323;
}

.text-wrap {
  inline-size: 150px;
  overflow-wrap: break-word;
}

.description {
  font-weight: 500;
  font-style: normal;
  color: #b5b8ba;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.hiring-heading {
  font-weight: 700 !important;
  margin-top: 10px !important;
  font-style: normal;
  text-align: center;
  flex: none;
  color: #ffffff;
}

.hiring-process-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-png {
  width: 30px;
}

.card-content {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 50px;
  box-shadow: 0px 0px 10px 0px #c5c5c5;
}

.align-center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.card-hover:hover {
  transform: translateY(-2px);
  cursor: pointer;
  /* opacity: 1.5; */
}

.card-box {
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #c5c5c5;
  margin-bottom: 30px;
  float: left;
  border-radius: 10px;
}

.card-box .card-thumbnail {
  max-height: 200px;
  overflow: hidden;
  border-radius: 10px;
  transition: 1s;
}

.card-box .card-thumbnail:hover {
  transform: scale(1.2);
}

.card-box h3 a {
  font-size: 20px;
  text-decoration: none;
}

@media (max-width: 768px) {
  .hiring-title {
    font-size: 24px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.152132px;
  }
  .hiring-heading {
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    font-weight: 500 !important;
    text-align: center;
    margin-top: 10px;
    color: #ffffff;
  }
  .hiring-img {
    width: 64px !important;
    height: 64px !important;
  }
  .description {
    padding-bottom: 20px !important;
  }
}
</style>
